.acCharger_main {
  margin-bottom: 3rem;
}

.acCharger_table_buttons {
  margin-right: 0.77rem;
  gap: 1rem;
  height: max-content;
}

.acCharger_button {
  width: auto;
  border-radius: 0.25rem;
  color: #fff;
  font-family: "Roboto";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.625rem 1.075rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qr {
  background: #ff4961;
}
.restart {
  background: #28d094;
}
.edit {
  background: #1e9ff2;
}
.configuration {
  background: #ff5c00;
}
.diagnostics {
  background: #000;
}
.acCharger_table {
}

.acCharger_table_main {
  border-collapse: collapse;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.acCharger_table_main td {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0.625rem;
  color: #000;
  font-family: "Roboto";
  font-size: 1rem;
  font-style: normal;
  line-height: normal;
}

.acCharger_table_label {
  width: 19.25rem;
  font-weight: 600;
}

.acCharger_table_data {
  width: 29.6875rem;
  color: #000;
  font-family: "Roboto";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
