.pulse {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #f57d36;
  box-shadow: 0 0 0 0 rgba(245, 125, 54, 0.5);
  animation: pulse-kefe91lg 1.2000000000000002s infinite linear;
  position: relative;
}

.pulse:before,
.pulse:after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  box-shadow: 0 0 0 0 rgba(245, 125, 54, 0.5);
  animation: inherit;
  animation-delay: -0.4s;
}

.pulse:after {
  animation-delay: -0.8s;
}

@keyframes pulse-kefe91lg {
  100% {
    box-shadow: 0 0 0 20px #0000;
  }
}
